<template>
  <div>
    <div>
      <v-img
        v-if="$root.$i18n.locale == 'fr'"
        class="mr-1 centerimg"
        src="../assets/img/WFH-AGS-2024-Logo-FR.png"
        max-height="180"
        max-width="600"
        contain
      ></v-img>
      <v-img
        v-else-if="$root.$i18n.locale == 'es'"
        class="mr-1 centerimg"
        src="../assets/img/WFH-AGS-2024-Logo-ES.png"
        max-height="180"
        max-width="600"
        contain
      ></v-img>
      <v-img
        v-else
        class="mr-1 centerimg"
        src="../assets/img/WFH-AGS-2024-Logo-EN.png"
        max-height="180"
        max-width="600"
        contain
      ></v-img>
    </div>
    <!-- <v-btn @click="$root.$i18n.locale = 'fr'">
      Swish lang - {{ $i18n.locale }}
    </v-btn> -->
    <h3 class="text-center mb-md">{{ $t("home.title") }}</h3>

    <!-- <h3 class="text-center mb-md">{{ $t("home.par1") }}</h3> -->
    <h3 class="text-center mb-md">{{ $t("home.par15") }}</h3>

    <h4 class="text-center">
      {{ $t("home.par2") }}
      <a
        href="https://wfh.org/research-and-data-collection/annual-global-survey/"
        >{{ $t("home.link") }}</a
      >
    </h4>
    <v-container v-if="canViewSurvey()">
      <v-btn class="center-home-btn" color="primary" :to="{ name: 'NewData' }">
        {{ $t("navbar.menu.currentsurvey") }}
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import { AuthRole } from "@/models/authrole";

export default {
  name: "Home",
  data() {
    return {};
  },
  methods: {
    canViewSurvey() {
      return this.isAdministrator() || this.isNmoUser() || this.isWfhEmployee();
    },
    isDDCUser() {
      return this.loggedInUserRole == AuthRole.DDC;
    },
    isWfhEmployee() {
      return this.loggedInUserRole == AuthRole.WFH;
    },
    isAdministrator() {
      return this.loggedInUserRole == AuthRole.Administrator;
    },
    isNmoUser() {
      return this.loggedInUserRole == AuthRole.NMO;
    },
  },
  computed: {
    loggedInUserRole() {
      return this.$store.getters["auth/getLoggedInUserRole"];
    },
  },
};
</script>
